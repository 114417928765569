import styled from "styled-components";

const HeadingText2 = styled.p`
    /* font-weight: bold;
    font-family: "Roboto Mono";
    font-size: 20px;
    letter-spacing: 0px;
    letter-spacing: 0px;
    color: #F19930;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 2%; */
    color: #F19930;
    font-size: "Roboto Mono";
    font-size: 1.9vw;
    font-weight: bold;
`
export default HeadingText2;