import styled from "styled-components";

const ParaText = styled.p`
   
    /* letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-d8d8d8);
    text-align: justify;
    font-style: 'medium';
    font-family: cursive;
    font-size: 20px;
    letter-spacing: 0px;
    color: #D8D8D8;
    opacity: 1; */
    color: #FFFFFF;
    font-size: "Roboto Mono";
    font-size: 1.8vw;
    text-align: justify;
`

export default ParaText;