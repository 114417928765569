import styled from "styled-components";

const HeadingText = styled.p`
    /* text-align: left;
    font-weight: bold;
    font-family: "Roboto Mono";
    font-size: 30px;

    letter-spacing: 0px;
    letter-spacing: 0px;
    color: #F19930;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 5%; */

    color: #F19930;
    font-size: "Roboto Mono";
    font-size: 2.2vw;
    font-weight:bold;
    /* margin-top: 5%; */
    padding-top: 8%;
`
export default HeadingText
